import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/AppointmentSection.css'; // Use a separate CSS file for the appointment section

const AppointmentSection = () => {
  const [service, setService] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [allowMessage, setAllowMessage] = useState(false);
  const [gdprAccepted, setGdprAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Make sure GDPR is accepted before proceeding
    if (!gdprAccepted) {
      alert('You must accept GDPR terms to proceed.');
      return;
    }
  
    // Prepare the form data for submission
    const formData = {
      service,
      name,
      email,
      message: allowMessage ? message : 'No message provided',
    };
  
    setLoading(true); // Set loading state to true while submitting
  
    try {
      // Update the fetch URL to point to the correct backend route
      const response = await fetch('/cgi-bin/app.py/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });      
  
      const data = await response.json();
  
      if (response.ok) {
        setSuccessMessage('Your request has been submitted successfully.');
        // Clear form fields after successful submission
        setService('');
        setName('');
        setEmail('');
        setMessage('');
        setAllowMessage(false);
        setGdprAccepted(false);
      } else {
        setErrorMessage(`Failed to submit: ${data.message}`);
      }
    } catch (error) {
      setErrorMessage('An error occurred while submitting the form. Please try again later.');
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };

  return (
    <div className="brand-landing">
      <div className="box box--light appointment-section">
        <h2>Book an Appointment</h2>
        <form onSubmit={handleSubmit} className="appointment-form">
          {/* Name Field */}
          <label htmlFor="name">Your Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          {/* Email Field */}
          <label htmlFor="email">Your Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          {/* Service Dropdown */}
          <label htmlFor="service">Choose a Service:</label>
          <select
            id="service"
            value={service}
            onChange={(e) => setService(e.target.value)}
            required
          >
            <option value="" disabled>
              Select a service
            </option>
            <option value="generative-ai">Generative AI</option>
            <option value="bp-automation">BP Automation</option>
            <option value="mobile-apps">Mobile Apps</option>
            <option value="web3-apps">Web3 Apps</option>
            <option value="brand-options">Inquire About Brand Options</option>
          </select>

          {/* Optional Message Checkbox */}
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="allowMessage"
              checked={allowMessage}
              onChange={() => setAllowMessage(!allowMessage)}
            />
            <label htmlFor="allowMessage">Leave a message?</label>
          </div>

          {/* Message Field (Visible only if checked) */}
          {allowMessage && (
            <textarea
              id="message"
              placeholder="Enter your message here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="4"
            />
          )}

          {/* GDPR Checkbox */}
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="gdprAccepted"
              checked={gdprAccepted}
              onChange={() => setGdprAccepted(!gdprAccepted)}
              required
            />
            <label htmlFor="gdprAccepted">
              I accept the <Link to="/components/AGB">GDPR terms</Link>
            </label>
          </div>

          {/* Submit Button */}
          <button type="submit" className="cta-button" disabled={loading}>
            {loading ? 'Submitting...' : 'Request by Mail'}
          </button>

          {/* Response Time Note */}
          <p className="response-time">
            *Current average response time: 10 work days
          </p>

          {/* Success or Error Message */}
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default AppointmentSection;
