import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2024 Make Europe GmbH. All rights reserved.</p>
      <div className="footer-links">
        <Link to="/agb" className="footer-link">AGB + Impressum</Link>
        <Link to="/" className="footer-link">Home</Link>
        <Link to="/brand" className="footer-link">Brand</Link>
        <Link to="/product" className="footer-link">Products</Link>
        <a href="mailto:roman@make-europe.com" className="footer-link">Contact Make Europe</a>
      </div>
      <div className="social-icons">
        {/* Add social media icons or links here */}
      </div>
      <div className="footer-notice">
        &copy; This site does NOT use cookies or collect data, so we do not need a privacy policy. Your emails to us will be saved and are considered as consent for mutual contact. Our website contains links to external websites of third parties over whose content we have no influence.
      </div>
    </footer>
  );
};

export default Footer;
